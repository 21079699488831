// --------------------------------------------------
//   Common (Header/Footer)
// --------------------------------------------------
table.crd-container-table {
  page-break-after: always;
  width: 100%;
  thead {
    display: table-header-group;
    th {
      font-weight: unset;
    }
  }
  tfoot {
    display: table-footer-group;
    .crd-signature-footer-spacer {
      // space for fixed footer so footer can be at bottom of the page when
      // the content doesn't reach the bottom but still not overlap when
      // the content does reach the bottom
      height: 3em;
    }
  }
}

.crd-header {
  .crd-header-box {
    border: 2px solid var(--bs-secondary);
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: var(--bs-body-bg);
    .crd-header-title {
      display: none;
    }
    .crd-header-box-content {
      display: flex;
      column-gap: 1rem;
      row-gap: 0.5rem;
      justify-content: space-between;
    }
  }
}

.crd-signature-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}

// --------------------------------------------------
//   Print
// --------------------------------------------------
@media print {
  .crd-header .crd-header-box {
    padding: 0.5rem 1rem;
    .crd-header-title {
      display: block;
      padding: 0;
      font-size: 1.5rem;
      text-align: left;
      grid-area: crd-hb-header;
    }
    .crd-header-box-content {
      display: grid;
      grid-template-columns: repeat(5, auto);
      grid-template-areas:
        'crd-hb-header crd-hb-model crd-hb-serial-number crd-hb-organization crd-hb-lot-numbers'
        'crd-hb-header crd-hb-label crd-hb-printed crd-hb-site crd-hb-lot-numbers';
      .info-piece {
        padding-bottom: 0.25rem;
        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }
  }
}
