@use '_mixins';

html {
  // browser default is 16px but you could also use 10px or 20px to make rem calculations easier
  font-size: 16px;
  scroll-behavior: smooth;
  scroll-padding: 8rem 0 0 0;
}

div#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

div#maincontent {
  flex-grow: 1;
  padding-bottom: 3rem;
}

// --------------------------------------------------
//   MISC. CLASSES
// --------------------------------------------------
.clickable {
  cursor: pointer;
}

.cursor-auto {
  cursor: auto;
}

select {
  cursor: pointer;
}

.nowrap {
  white-space: nowrap;
}

table > * {
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
  color-adjust: exact !important; /*Firefox*/
}

.table.table-header-primary {
  thead {
    background: $primary;
    color: $white;
  }
}

.table-top-bar {
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  .table-top-section {
    display: flex;
    div {
      margin-bottom: 0;
    }
  }
}

.tab-content {
  padding-top: 2rem;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.alert p:last-child {
  margin-bottom: 0;
}

.info-icon {
  height: 0.75em;
  margin-top: -0.25em;
  margin-left: 0.25em;
}

// Using grid-template-columns: 1fr auto 1fr; makes the sides the same width
// which means the center will actually be center. Plus, using the area
// templates makes it a lot easier to implement the mobile layout.
.straddled-header {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: 'left header right';
  column-gap: 1rem;
  align-items: end;
  h1 {
    grid-area: header;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  & > :first-child {
    grid-area: left;
  }
  & > :last-child {
    grid-area: right;
  }
  @include media-breakpoint-up(lg) {
    & > :first-child {
      justify-self: start;
    }
    & > :last-child {
      justify-self: end;
    }
  }
  @include media-breakpoint-down(lg) {
    grid-template-columns: auto;
    grid-row-gap: 1rem;
    grid-template-areas:
      'header header'
      'left right';
  }
}
