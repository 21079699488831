@import 'mixins';

.pagebreak {
  display: none;
}
.print-avoid-break-inside {
  page-break-inside: avoid !important;
}

@media print {
  #maincontent.container {
    padding: 0;
  }
  body {
    font-size: 12px;
  }

  .pagebreak {
    clear: both;
    page-break-after: always;
    display: block;
  }
  .container {
    max-width: unset;
  }
  .btn {
    display: none;
  }
  // Tabs
  .nav-tabs {
    display: none;
  }
  .tab-content {
    padding-top: 0;
    .tab-pane {
      display: block;
      opacity: 1;
      padding: 0.5rem 1rem;
      border-bottom: 2px solid $secondary;
      page-break-inside: avoid;
    }
  }

  // Font
  .print-small {
    font-size: $small-font-size;
  }

  .table-print-sm {
    // stylelint-disable-next-line selector-max-universal
    > :not(caption) > * > * {
      padding: $table-cell-padding-y-sm $table-cell-padding-x-sm;
    }
  }

  @include make-print-columns();
}
