// Variables
//
// https://getbootstrap.com/docs/5.0/customize/options/
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
// Docs say apple green should be #7ab929 but we are already using the minimum contrast ratio
// (see contrast ratio below) so in order to get the white text on the green buttons white still
// being WCAG compliant, we use this other green found on their marketing site.
/*
  IMPORTANT
  Remember to update color values in constants.ts
*/
$white: #fff;
$green-apple: #6d9e14;
$slate: #3a5b73;
$almond: #adce74;
// scss-docs-end color-variables

// scss-docs-start colors-map
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: $green-apple;
$secondary: $slate;
$success: $primary;
$info: $secondary;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
// scss-docs-end theme-colors-map

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
// Also https://webaim.org/resources/contrastchecker/
$min-contrast-ratio: 3;

// Customize the light and dark text colors for use in our color contrast function.

// fusv-disable
// fusv-enable

// Characters which are escaped by the escape-svg function

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

// Prefix for :root CSS variables

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
// scss-docs-start variable-gradient
// scss-docs-end variable-gradient

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
// scss-docs-end spacer-variables-maps

// Position
//
// Define the edge positioning anchors of the position utilities.

// scss-docs-start position-map
// scss-docs-end position-map

// Body
//
// Settings for the `<body>` element.

// Links
//
// Style anchor elements.
$link-color: $secondary;

// Paragraphs
//
// Style p element.

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
// scss-docs-end grid-breakpoints

// @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
// @include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
// scss-docs-end container-max-widths

// @include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-gutter-width: 2rem;

// Container padding

// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius: 4px;
$border-radius-sm: 5px;
$border-radius-lg: 6px;
// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
// scss-docs-end box-shadow-variables

// scss-docs-start caret-variables
// scss-docs-end caret-variables

// scss-docs-start collapse-transition
// scss-docs-end collapse-transition

// stylelint-disable function-disallowed-list
// scss-docs-start aspect-ratios
// scss-docs-end aspect-ratios
// stylelint-enable function-disallowed-list

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
// stylelint-enable value-keyword-case

// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.5;
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom: 1rem;
$headings-color: $primary;
// scss-docs-end headings-variables

// scss-docs-start display-headings
// scss-docs-end display-headings

// scss-docs-start type-variables
// scss-docs-end type-variables

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-striped-bg-factor: 0.1;
$table-striped-bg: rgba($primary, $table-striped-bg-factor);

$table-hover-bg-factor: 0.15;
$table-hover-bg: rgba($primary, $table-hover-bg-factor);

$table-striped-order: even;
// scss-docs-end table-variables

// scss-docs-start table-loop
// scss-docs-end table-loop

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-focus-width: 2px;
// scss-docs-end input-btn-variables

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
// scss-docs-end btn-variables

// Forms

// scss-docs-start form-text-variables
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
// scss-docs-end form-input-variables

// scss-docs-start form-check-variables
// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
// scss-docs-end form-switch-variables

// scss-docs-start input-group-variables
// scss-docs-end input-group-variables

// scss-docs-start form-select-variables
// scss-docs-end form-select-variables

// scss-docs-start form-range-variables
// scss-docs-end form-range-variables

// scss-docs-start form-file-variables
// scss-docs-end form-file-variables

// scss-docs-start form-floating-variables
// scss-docs-end form-floating-variables

// Form validation

// scss-docs-start form-feedback-variables
// scss-docs-end form-feedback-variables

// scss-docs-start form-validation-states
// scss-docs-end form-validation-states

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
// scss-docs-end zindex-stack

// Navs

// scss-docs-start nav-variables
// scss-docs-end nav-variables

// Navbar

// scss-docs-start navbar-variables
// scss-docs-end navbar-variables

// scss-docs-start navbar-theme-variables
$navbar-light-color: $secondary;
$navbar-light-hover-color: $primary;
$navbar-light-active-color: $primary;
$navbar-light-disabled-color: rgba($secondary, 0.3);
$navbar-light-toggler-border-color: rgba($secondary, 0.1);
// scss-docs-end navbar-theme-variables

// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-color: $white;
$dropdown-bg: $slate;
$dropdown-border-color: shade-color($slate, 25%);
$dropdown-link-color: $white;
$dropdown-link-hover-color: $almond;
$dropdown-link-hover-bg: $slate;
// scss-docs-end dropdown-variables

// scss-docs-start dropdown-dark-variables
// scss-docs-end dropdown-dark-variables

// Pagination

// scss-docs-start pagination-variables
// scss-docs-end pagination-variables

// Cards

// scss-docs-start card-variables
$card-border-width: 2px;
$card-border-color: $primary;
$card-cap-bg: $primary;
$card-cap-color: $white;
// scss-docs-end card-variables

// Accordion

// scss-docs-start accordion-variables
// scss-docs-end accordion-variables

// Tooltips

// scss-docs-start tooltip-variables
// scss-docs-end tooltip-variables

// Form tooltips must come after regular tooltips
// scss-docs-start tooltip-feedback-variables
// scss-docs-end tooltip-feedback-variables

// Popovers

// scss-docs-start popover-variables
// scss-docs-end popover-variables

// Toasts

// scss-docs-start toast-variables
// scss-docs-end toast-variables

// Badges

// scss-docs-start badge-variables
$badge-border-radius: 1rem;
// scss-docs-end badge-variables

// Modals

// scss-docs-start modal-variables
// scss-docs-end modal-variables

// Alerts
//
// Define alert colors, border radius, and padding.

// scss-docs-start alert-variables
// scss-docs-end alert-variables

// Progress bars

// scss-docs-start progress-variables
// scss-docs-end progress-variables

// List group

// scss-docs-start list-group-variables
// scss-docs-end list-group-variables

// Image thumbnails

// scss-docs-start thumbnail-variables
// scss-docs-end thumbnail-variables

// Figures

// scss-docs-start figure-variables
// scss-docs-end figure-variables

// Breadcrumbs

// scss-docs-start breadcrumb-variables
// scss-docs-end breadcrumb-variables

// Carousel

// scss-docs-start carousel-variables
// scss-docs-end carousel-variables

// Spinners

// scss-docs-start spinner-variables
// scss-docs-end spinner-variables

// Close

// scss-docs-start close-variables
// scss-docs-end close-variables

// Offcanvas

// scss-docs-start offcanvas-variables
// scss-docs-end offcanvas-variables

// Code
