.form-group {
  margin-bottom: 1rem;
}

.form-label {
  font-weight: bold;
  font-size: 0.8rem;
  color: $secondary;
  margin-bottom: 0.2rem;
  span {
    font-weight: normal;
  }
}

.react-time-picker {
  width: 100%;
  .react-time-picker__wrapper {
    border: none;
  }
  .react-time-picker__inputGroup {
    @extend .form-control;
  }
}
