@import './essentials';

.unauth-card {
  width: 25rem;
  margin: auto;
  box-shadow: 5px 5px 20px #ccc;
  &.wide {
    width: 40rem;
  }
  .card-header {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
  }
  .card-footer {
    margin-top: 1rem;
  }
}
