@import 'node_modules/bootstrap/scss/mixins/grid';
@import './essentials';

@mixin make-print-columns(
  $columns: $grid-columns,
  $gutter: $grid-gutter-width
) {
  .col-print {
    flex: 1 0 0%; // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
  }

  .row-cols-print-auto > * {
    @include make-col-auto();
  }

  @if $grid-row-columns > 0 {
    @for $i from 1 through $grid-row-columns {
      .row-cols-print-#{$i} {
        @include row-cols($i);
      }
    }
  }

  .col-print-auto {
    @include make-col-auto();
  }

  @if $columns > 0 {
    @for $i from 1 through $columns {
      .col-print-#{$i} {
        @include make-col($i, $columns);
      }
    }

    // `$columns - 1` because offsetting by the width of an entire row isn't possible
    @for $i from 0 through ($columns - 1) {
      @if not($i == 0) {
        // Avoid emitting useless .offset-0
        .offset-print-#{$i} {
          @include make-col-offset($i, $columns);
        }
      }
    }
  }

  // Gutters
  //
  // Make use of `.g-*`, `.gx-*` or `.gy-*` utilities to change spacing between the columns.
  @each $key, $value in $gutters {
    .g-print-#{$key},
    .gx-print-#{$key} {
      --#{$variable-prefix}gutter-x: #{$value};
    }

    .g-print-#{$key},
    .gy-print-#{$key} {
      --#{$variable-prefix}gutter-y: #{$value};
    }
  }
}
