// In alphabetical order

// --------------------------------------------------
//   FOOTER
// --------------------------------------------------
.footer {
  padding: 1rem;
  background-color: $primary;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include media-breakpoint-up(md) {
    padding: 1.6rem 3.2rem;
    background: inherit;
  }
}

// --------------------------------------------------
//   HEADER
// --------------------------------------------------

.navbar {
  border-bottom: 0.3rem solid $primary;
  margin-bottom: 3.1rem;
  background-color: $white;
  .nav-link {
    font-weight: bold;
    text-transform: uppercase;
  }
  .header-logo {
    height: 4rem;
  }
}

// --------------------------------------------------
//   INFO PIECE
// --------------------------------------------------

.info-piece {
  padding-bottom: 1rem;
  page-break-inside: avoid !important;
  .label {
    font-weight: bold;
    font-size: 0.8rem;
    color: $secondary;
  }
  .content {
    line-height: 120%;
  }
}

// --------------------------------------------------
//   LOT GROUP INFO
// --------------------------------------------------

.lot-box {
  background-color: rgba($secondary, 0.1);
  border: 0.1rem solid $secondary;
  padding: 1rem;
  margin-bottom: 3rem;
}

.lot-group-info {
  table {
    margin: 0.5rem 0;
    thead {
      font-weight: bold;
      font-size: 0.8rem;
      color: $secondary;
      border-bottom: 0.1rem solid $secondary;
    }
    td,
    th {
      padding: 0 1rem;
    }
  }
}

// --------------------------------------------------
//   Help (User Manual)
// --------------------------------------------------

.sticky-sidebar {
  position: sticky;
  top: 8rem;
  .isCurrent {
    @extend .active;
    font-weight: bold;
  }
  .scrollspy {
    padding-left: 0;
  }
}

.help-bottom-space {
  height: 200px;
}

.help-button {
  text-decoration: none;
  background-color: $secondary;
  color: $white;
  display: inline-block;
  width: 0.85rem;
  height: 0.85rem;
  text-align: center;
  padding: none;
  border-radius: 100%;
  cursor: pointer;
  margin-left: 0.3rem;
  font-size: 0.65rem;
}

// --------------------------------------------------
//   Search
// --------------------------------------------------

.search-bar {
  width: 30rem;
  button[type='submit'] {
    border-start-start-radius: 0;
    border-end-start-radius: 0;
  }
}

// --------------------------------------------------
//   Signature Line
// --------------------------------------------------
.signature-line-wrapper {
  display: none;
  width: 100%;
  grid-gap: 01rem;
  .label-line-pair {
    display: flex;
    grid-gap: 0.5rem;
    &.signature {
      flex-grow: 2;
    }
    &.date {
      flex-grow: 1;
    }
    .line {
      border-bottom: 2px solid $dark;
      flex-grow: 1;
    }
  }
}
@media print {
  .signature-line-wrapper {
    display: flex;
  }
}
